<template>
  <div class="optional-products-carousel">
    <b-carousel
      :id="`optional-products-carousel-${uid}`"
      :ref="`optional-products-carousel-${uid}`"
      v-model="carouselSlide"
      :interval="0"
      indicators
    >
      <p v-if="header" class="text-center mt-3 mb-4">
        {{ header }}
      </p>
      <b-carousel-slide
        v-for="(itemSet, index) in itemSets"
        :key="index"
      >
        <!-- Despite the slot's name, you can place almost any content in this slot in lieu of using
        the default slot or caption and text props. Source: https://bootstrap-vue.org/docs/components/carousel -->
        <template #img>
          <div class="optional-products-carousel-items">
            <div
              v-for="(item, index2) in filteredItemSet(itemSet)"
              :key="index2"
              v-b-popover.hover.left="item.stateJurisdiction ? displayDisclaimerText(item) : null"
              class="optional-products-carousel-item"
              :class="{
                selected: isSelected(item),
                disabled: optionalItemDisabled(item),
                removingItem: itemRemovedFromCart(item),
                'optional-products-carousel-item-sub-products': item?.products || item?.optionalFilingMethods,
                'focus-on-back-card': focusOnBackCard(item),
              }"
              @click="toggleSelection(item)"
              @mouseleave="forceCarouselItemActive = false"
            >
              <check-circle v-if="itemInCart(item)" class="check-circle" width="20" height="20" />

              <div
                class="optional-products-carousel-item-inner"
                :class="{'focus-on-back-card': focusOnBackCard(item) }"
              >
                <div
                  class="optional-products-carousel-item-front"
                  :class="{'focus-on-back-card': focusOnBackCard(item)}"
                >
                  <img :src="imageSource(item)" :alt="item.displayName">
                  <h5 class="title">
                    {{ item.displayName }}
                  </h5>
                  <p class="description">
                    {{ description(item) }}
                  </p>

                  <div class="price-container">
                    <h3 class="price">
                      {{ item.displayPrice }}
                    </h3>
                  </div>
                </div>

                <div
                  v-if="item?.products"
                  class="optional-products-carousel-item-back"
                  :class="{'focus-on-back-card': focusOnBackCard(item)}"
                >
                  <img :src="imageSource(item)" :alt="item.displayName">
                  <h5 class="title">
                    {{ item.displayName }}
                  </h5>

                  <div
                    v-for="subProduct in item.products"
                    :key="subProduct.id"
                    class="mb-3"
                  >
                    <b-button
                      class="sub-product-button"
                      :class="{
                        selected: isSelected(subProduct),
                        removingItem: itemRemovedFromCart(subProduct),
                      }"
                      :disabled="optionalItemDisabled(subProduct) || productSelectionInProgress(item)"
                      aria-label="select product button"
                      @click="toggleSelection(subProduct)"
                    >
                      <span v-html="subProductTitle(subProduct?.displayName)" />
                      <br>
                      <span class="sub-product-button-price">
                        {{ subProduct?.displayPrice }}
                      </span>
                    </b-button>
                  </div>
                </div>

                <div
                  v-if="item?.optionalFilingMethods"
                  class="optional-products-carousel-item-back"
                  :class="{'focus-on-back-card': focusOnBackCard(item)}"
                >
                  <img :src="imageSource(item)" :alt="item.displayName">
                  <h5 class="title">
                    {{ item.displayName }}
                  </h5>

                  <multi-select
                    id="optional-filing-selection"
                    v-model="optionalFilingMethodSelection"
                    :multiple="true"
                    :placeholder="'City / County to File'"
                    :options="item.optionalFilingMethods"
                    :max-height="150"
                    label="local_jurisdiction"
                    track-by="name"
                    @select="option => selectedOptionalMethod(item, option)"
                    @remove="option => removedOptionalMethod(item, option)"
                  >
                    <template v-slot:selection="{ values, search, isOpen }">
                      <span
                        v-if="values.length"
                        v-show="!isOpen"
                        class="multiselect__single"
                      >
                        {{ values.length }} options selected
                      </span>
                    </template>
                    <template v-slot:option="props">
                      {{ props.option.local_jurisdiction + ' - $' + (item.price + props.option.cost) }}
                    </template>
                  </multi-select>

                  <small>* If your city/county is not listed in the dropdown above, please email us so
                    we can determine if it can be added and facilitate the filing process for you.</small>
                </div>
              </div>
            </div>
          </div>
        </template>
      </b-carousel-slide>
      <div class="optional-products-carousel-controls-container">
        <div class="optional-products-carousel-controls">
          <button aria-label="previous button" @click="prev()">
            <feather-icon type="chevron-left" stroke-width="1" width="50" height="50" />
          </button>
          <button aria-label="continue button" @click="next()">
            <feather-icon type="chevron-right" stroke-width="1" width="50" height="50" />
          </button>
        </div>
      </div>
    </b-carousel>
  </div>
</template>

<script>

import { mapGetters } from 'vuex'
import { optionalItemSelected } from '@/components/HireUs/optionalItemHelper'
import { dbaJurisdictionDisclaimers } from '@/common/modules/jurisdictionDisclaimers'
import MultiSelect from 'vue-multiselect'
import * as DOMPurify from 'dompurify'

export default {
  name: 'OptionalProductsCarousel',
  components: {
    FeatherIcon: () => import('@/components/shared/FeatherIcon'),
    CheckCircle: () => import('@images/ui/check-circle.svg'),
    MultiSelect,
  },
  props: {
    header: {
      type: String,
      default: '',
    },
    items: {
      type: Array,
      default() {
        return []
      },
    },
    itemsInCart: {
      type: Array,
      default() {
        return []
      },
    },
    removeProducts: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      uid: this._uid,
      carouselSlide: 0,
      selectedItems: [],
      itemsAddingToCart: [],
      itemsRemovingFromCart: [],
      forceCarouselItemActive: false,
      taxId: {
        ssn: 'ssn tax id',
        noSsn: 'no-ssn tax id',
      },
      optionalFilingMethodSelection: [],
    }
  },
  computed: {
    ...mapGetters('checkout', [
      'cartItems',
      'findCartItem',
      'ineligibleToOrderNonSSNTaxId',
      'ineligibleToOrderSSNTaxId',
    ]),
    itemSets() {
      const sets = []
      const optionalItems = this.removeProducts ? this.itemsInCart : this.items

      const n = this.$mq === 'sm' ? 1 : 3
      for (let i = 0; i < optionalItems.length; i += n) {
        sets.push(optionalItems.slice(i, i + n))
      }
      return sets
    },
  },
  async mounted() {
    if (this.itemsInCart.length) this.selectedItems = this.itemsInCart
  },
  methods: {
    filteredItemSet(itemSet) {
      return itemSet.filter(item => item.defaultFilingMethod !== 'none')
    },
    productIds(item) {
      return item?.productIds || [item.id]
    },
    description(item) {
      return item.description.length > 180 ?
        item.description.slice(0,180) + '...' :
        item.description
    },
    imageSource(item) {
      const product = item?.products?.length ? item.products[0] : item
      const fileName = product?.imageKey
      let image
      try {
        image = require(`@images/illustrations/products/${fileName}.png`)
      } catch(e) {
        image = require(`@images/illustrations/products/default.png`)
      }
      return image
    },
    isSelected(item) {
      const singleItemSelected = !item.optionalFilingMethods && (this.cartItems.some(i => this.productIds(item).includes(i.product_id))  ||
        this.itemAddedToCart(item))

      const filingMethodSelected = item.optionalFilingMethods && (this.cartItems.some(i => item.optionalFilingMethods.some(f => f.id === i.filing_method_id)) ||
        this.itemsAddingToCart.some(i => item.optionalFilingMethods.some(f => f.id === i.defaultFilingMethod.id)))

      return singleItemSelected || filingMethodSelected
    },
    shouldAddOptionalMethod (item) {
      if (!item.optionalFilingMethods) return false

      return this.cartItems.every(i => item.defaultFilingMethod.id !== i.filing_method_id)
    },
    itemRemovedFromCart(item) {
      return this.itemsRemovingFromCart.some(i => this.productIds(item).includes(i.id))
    },
    itemAddedToCart(item) {
      return this.itemsAddingToCart.some(i => this.productIds(item).includes(i.id))
    },
    itemInCart(item) {
      return this.isSelected(item) &&
        !this.itemsRemovingFromCart.some(i => this.productIds(item).includes(i.id))
    },
    async toggleSelection(item, optionalMethodSelection = false) {
      if (item?.products || (item?.optionalFilingMethods && !optionalMethodSelection)) return

      if (this.isSelected(item) && !this.shouldAddOptionalMethod(item)) {
        this.itemsRemovingFromCart.push(item)
        this.forceCarouselItemActive = this.taxIdSubName(item)
        this.selectedItems = this.selectedItems.filter(i => i.id !== item.id)

        await optionalItemSelected(item, false)
        this.itemsRemovingFromCart = this.itemsRemovingFromCart.filter(i => i.id !== item.id)
      } else {
        this.itemsAddingToCart.push(item)
        this.selectedItems.push(item)
        await optionalItemSelected(item, true)
        this.itemsAddingToCart = this.itemsAddingToCart.filter(i => i.id !== item.id)
      }
      this.$emit('changed', this.selectedItems)
    },
    selectedOptionalMethod (item, filingMethod) {
      item.defaultFilingMethod = filingMethod
      this.toggleSelection(item, true)
    },
    removedOptionalMethod (item, filingMethod) {
      item.defaultFilingMethod = filingMethod
      this.toggleSelection(item, true)
    },
    prev() {
      this.$refs[`optional-products-carousel-${this.uid}`].prev()
    },
    next() {
      this.$refs[`optional-products-carousel-${this.uid}`].next()
    },
    optionalItemDisabled(item) {
      if (item?.products) return false

      if (item.filing_sub_name === this.taxId.noSsn) {
        return this.ineligibleToOrderNonSSNTaxId ||
          this.itemsAddingToCart.some(i => i?.filing_sub_name === this.taxId.ssn)
      } else if (item.filing_sub_name === this.taxId.ssn) {
        return this.ineligibleToOrderSSNTaxId ||
          this.itemsAddingToCart.some(i => i?.filing_sub_name === this.taxId.noSsn)
      }
    },
    subProductTitle(title) {
      const sanitizedTitle = title.includes(":")
        ? title.replace(":", "<br/>")
        : title
      return DOMPurify.sanitize(sanitizedTitle)
    },
    taxIdSubName(item) {
      return item?.filing_sub_name === this.taxId.noSsn ||
        item?.filing_sub_name === this.taxId.ssn
    },
    productSelectionInProgress(item) {
      return this.itemAddedToCart(item) ||
        this.itemRemovedFromCart(item)
    },
    focusOnBackCard(item) {
      return item?.products &&
        (
          this.forceCarouselItemActive ||
          this.itemInCart(item) ||
          this.productSelectionInProgress(item)
        )
    },
    displayDisclaimerText(item) {
      const jurisdictionDisclaimers = dbaJurisdictionDisclaimers

      if (jurisdictionDisclaimers[item.stateJurisdiction]) {
        return jurisdictionDisclaimers[item.stateJurisdiction]
      }

      return false
    },
  },
}
</script>

<style lang="scss" scoped>

.optional-products-carousel {
  p {
    font-size: 1.1rem;
  }
  .carousel-control-prev,
  .carousel-control-next {
    width: 4%;
  }
  .carousel-control-prev-icon,
  .carousel-control-next-icon {
    filter: invert(1);
  }
  ::v-deep .carousel-indicators {
    bottom: 5px;
    li {
      border-radius: 100%;
      margin: 0 0.188em;
      padding: unset;
      width: 20px;
      height: 0;
      background: $ct-ui-color-22;
      transform: scale(.7) !important;
      &.active {
        background: $ct-ui-primary;
      }
    }
    margin-bottom: 0.375em;
  }
  .carousel-item {
    width: 100%;
    left: 2%;
    right: 2%;
  }
  .optional-products-carousel-items {
    padding: 0.625em 0 4.375em 0;
    display: flex;
    width: 100%;
    flex-direction: row;
    display: grid;
    grid-template-columns: 33.33% 33.33% 33.33%;
    positive: relative;

    .optional-products-carousel-item-inner {
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      height: 100%;
      width: 100%;
      &:not(.focus-on-back-card) {
        transition: transform 0.8s;
      }
      transform-style: preserve-3d;
    }
    .optional-products-carousel-item {
      @include ct-ui-selectable;
      @include ct-ui-card-shadow;
      padding: 1.563em 1.25em 1.875em 1.25em;
      height: 375px;
      width: 275px;
      &.selected {
        border: 2px solid $ct-ui-primary;
        ::v-deep .check-circle {
          position: absolute;
          transform: scale(1.2);
          top: 20px;
          right: 20px;
          path {
            fill: $ct-ui-primary !important;
          }
        }
      }
      &.disabled {
        opacity: .5;
        pointer-events: none;
      }
      &.removingItem {
        border: 1px solid #d4d4d4;
      }
      img {
        height: 80px;
        margin: 0 0;
      }
      h5.title {
        margin-top: 0.625em;
        font-weight: 800 !important;
        font-size: 1.1rem;
        padding: 0.625em 1.25em;
      }
      p.description {
        font-size: 0.9rem;
        margin: unset;
        color: black !important;
        font-weight: 400 !important;
      }
      h3.price {
        margin: unset;
        font-weight: 700;
        font-size: 1.3rem;
      }
    }
  }

  .optional-products-carousel-controls-container {
    position: absolute;
    bottom: -3px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    .optional-products-carousel-controls {
      width: 320px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      button {
        background: unset;
        border: unset;
        outline: unset;
        opacity: 0.4;
        z-index: 20;
        &:hover {
          opacity: 0.6;
        }
      }
    }
  }
  @media only screen and (max-width: 1200px) {
    .carousel-item {
      width: 100%;
      left: 0;
      right: 0;
    }
  }
  @media only screen and (max-width: 992px) and (min-width: 736px) {
    .optional-products-carousel-item {
      zoom: 0.8;
    }
  }
  @media only screen and (max-width: 768px) and (min-width: 736px) {
    .optional-products-carousel-item {
      img {
        display: none;
      }
      .title {
        margin-top: 0;
      }
    }
  }
}
.sub-product-button {
  width: 100%;
  background: white;
  color: black;
  border: $ct-ui-primary 1px solid;
  box-shadow: 0px 3px 4px #d4d4d4;
  font-size: 1rem;
  &:hover {
    bottom: 5px;
    box-shadow: 0px 0px 4px 4px #00000066;
  }
  &.removingItem {
    border: $ct-ui-primary 1px solid !important;
    background: white !important;
    color: black !important;
  }
  &.selected {
    background: $ct-ui-primary;
    color: white;
  }
  &.disabled {
    pointer-events: none;
    opacity: .5;
  }
}
.sub-product-button-price {
  font-weight: 700;
  font-size: 1.3rem;
}
.optional-products-carousel-item-sub-products {
  perspective: 1000px; /* Remove this if you don't want the 3D effect */
}
.optional-products-carousel-item-sub-products:not(.focus-on-back-card):hover .optional-products-carousel-item-inner {
  transform: rotateY(180deg);
}
.optional-products-carousel-item-front, .optional-products-carousel-item-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
}
.optional-products-carousel-item-front {
  color: black;
  &.focus-on-back-card {
    transform: rotateY(180deg);
  }
}
.optional-products-carousel-item-back {
  color: black;
  transform: rotateY(180deg);
  cursor: default;
  &.focus-on-back-card {
    transform: unset;
  }
}
.price-container {
  width: 100%;
  position: absolute;
  bottom: 0;
  text-align: center
}
</style>
