var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "optional-products-carousel" },
    [
      _c(
        "b-carousel",
        {
          ref: `optional-products-carousel-${_vm.uid}`,
          attrs: {
            id: `optional-products-carousel-${_vm.uid}`,
            interval: 0,
            indicators: "",
          },
          model: {
            value: _vm.carouselSlide,
            callback: function ($$v) {
              _vm.carouselSlide = $$v
            },
            expression: "carouselSlide",
          },
        },
        [
          _vm.header
            ? _c("p", { staticClass: "text-center mt-3 mb-4" }, [
                _vm._v("\n      " + _vm._s(_vm.header) + "\n    "),
              ])
            : _vm._e(),
          _vm._l(_vm.itemSets, function (itemSet, index) {
            return _c("b-carousel-slide", {
              key: index,
              scopedSlots: _vm._u(
                [
                  {
                    key: "img",
                    fn: function () {
                      return [
                        _c(
                          "div",
                          { staticClass: "optional-products-carousel-items" },
                          _vm._l(
                            _vm.filteredItemSet(itemSet),
                            function (item, index2) {
                              return _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "b-popover",
                                      rawName: "v-b-popover.hover.left",
                                      value: item.stateJurisdiction
                                        ? _vm.displayDisclaimerText(item)
                                        : null,
                                      expression:
                                        "item.stateJurisdiction ? displayDisclaimerText(item) : null",
                                      modifiers: { hover: true, left: true },
                                    },
                                  ],
                                  key: index2,
                                  staticClass:
                                    "optional-products-carousel-item",
                                  class: {
                                    selected: _vm.isSelected(item),
                                    disabled: _vm.optionalItemDisabled(item),
                                    removingItem: _vm.itemRemovedFromCart(item),
                                    "optional-products-carousel-item-sub-products":
                                      item?.products ||
                                      item?.optionalFilingMethods,
                                    "focus-on-back-card":
                                      _vm.focusOnBackCard(item),
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.toggleSelection(item)
                                    },
                                    mouseleave: function ($event) {
                                      _vm.forceCarouselItemActive = false
                                    },
                                  },
                                },
                                [
                                  _vm.itemInCart(item)
                                    ? _c("check-circle", {
                                        staticClass: "check-circle",
                                        attrs: { width: "20", height: "20" },
                                      })
                                    : _vm._e(),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "optional-products-carousel-item-inner",
                                      class: {
                                        "focus-on-back-card":
                                          _vm.focusOnBackCard(item),
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "optional-products-carousel-item-front",
                                          class: {
                                            "focus-on-back-card":
                                              _vm.focusOnBackCard(item),
                                          },
                                        },
                                        [
                                          _c("img", {
                                            attrs: {
                                              src: _vm.imageSource(item),
                                              alt: item.displayName,
                                            },
                                          }),
                                          _c("h5", { staticClass: "title" }, [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(item.displayName) +
                                                "\n                "
                                            ),
                                          ]),
                                          _c(
                                            "p",
                                            { staticClass: "description" },
                                            [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm.description(item)
                                                  ) +
                                                  "\n                "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "price-container" },
                                            [
                                              _c(
                                                "h3",
                                                { staticClass: "price" },
                                                [
                                                  _vm._v(
                                                    "\n                    " +
                                                      _vm._s(
                                                        item.displayPrice
                                                      ) +
                                                      "\n                  "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      item?.products
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "optional-products-carousel-item-back",
                                              class: {
                                                "focus-on-back-card":
                                                  _vm.focusOnBackCard(item),
                                              },
                                            },
                                            [
                                              _c("img", {
                                                attrs: {
                                                  src: _vm.imageSource(item),
                                                  alt: item.displayName,
                                                },
                                              }),
                                              _c(
                                                "h5",
                                                { staticClass: "title" },
                                                [
                                                  _vm._v(
                                                    "\n                  " +
                                                      _vm._s(item.displayName) +
                                                      "\n                "
                                                  ),
                                                ]
                                              ),
                                              _vm._l(
                                                item.products,
                                                function (subProduct) {
                                                  return _c(
                                                    "div",
                                                    {
                                                      key: subProduct.id,
                                                      staticClass: "mb-3",
                                                    },
                                                    [
                                                      _c(
                                                        "b-button",
                                                        {
                                                          staticClass:
                                                            "sub-product-button",
                                                          class: {
                                                            selected:
                                                              _vm.isSelected(
                                                                subProduct
                                                              ),
                                                            removingItem:
                                                              _vm.itemRemovedFromCart(
                                                                subProduct
                                                              ),
                                                          },
                                                          attrs: {
                                                            disabled:
                                                              _vm.optionalItemDisabled(
                                                                subProduct
                                                              ) ||
                                                              _vm.productSelectionInProgress(
                                                                item
                                                              ),
                                                            "aria-label":
                                                              "select product button",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.toggleSelection(
                                                                subProduct
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c("span", {
                                                            domProps: {
                                                              innerHTML: _vm._s(
                                                                _vm.subProductTitle(
                                                                  subProduct?.displayName
                                                                )
                                                              ),
                                                            },
                                                          }),
                                                          _c("br"),
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "sub-product-button-price",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                      " +
                                                                  _vm._s(
                                                                    subProduct?.displayPrice
                                                                  ) +
                                                                  "\n                    "
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                }
                                              ),
                                            ],
                                            2
                                          )
                                        : _vm._e(),
                                      item?.optionalFilingMethods
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "optional-products-carousel-item-back",
                                              class: {
                                                "focus-on-back-card":
                                                  _vm.focusOnBackCard(item),
                                              },
                                            },
                                            [
                                              _c("img", {
                                                attrs: {
                                                  src: _vm.imageSource(item),
                                                  alt: item.displayName,
                                                },
                                              }),
                                              _c(
                                                "h5",
                                                { staticClass: "title" },
                                                [
                                                  _vm._v(
                                                    "\n                  " +
                                                      _vm._s(item.displayName) +
                                                      "\n                "
                                                  ),
                                                ]
                                              ),
                                              _c("multi-select", {
                                                attrs: {
                                                  id: "optional-filing-selection",
                                                  multiple: true,
                                                  placeholder:
                                                    "City / County to File",
                                                  options:
                                                    item.optionalFilingMethods,
                                                  "max-height": 150,
                                                  label: "local_jurisdiction",
                                                  "track-by": "name",
                                                },
                                                on: {
                                                  select: (option) =>
                                                    _vm.selectedOptionalMethod(
                                                      item,
                                                      option
                                                    ),
                                                  remove: (option) =>
                                                    _vm.removedOptionalMethod(
                                                      item,
                                                      option
                                                    ),
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "selection",
                                                      fn: function ({
                                                        values,
                                                        search,
                                                        isOpen,
                                                      }) {
                                                        return [
                                                          values.length
                                                            ? _c(
                                                                "span",
                                                                {
                                                                  directives: [
                                                                    {
                                                                      name: "show",
                                                                      rawName:
                                                                        "v-show",
                                                                      value:
                                                                        !isOpen,
                                                                      expression:
                                                                        "!isOpen",
                                                                    },
                                                                  ],
                                                                  staticClass:
                                                                    "multiselect__single",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                      " +
                                                                      _vm._s(
                                                                        values.length
                                                                      ) +
                                                                      " options selected\n                    "
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key: "option",
                                                      fn: function (props) {
                                                        return [
                                                          _vm._v(
                                                            "\n                    " +
                                                              _vm._s(
                                                                props.option
                                                                  .local_jurisdiction +
                                                                  " - $" +
                                                                  (item.price +
                                                                    props.option
                                                                      .cost)
                                                              ) +
                                                              "\n                  "
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  true
                                                ),
                                                model: {
                                                  value:
                                                    _vm.optionalFilingMethodSelection,
                                                  callback: function ($$v) {
                                                    _vm.optionalFilingMethodSelection =
                                                      $$v
                                                  },
                                                  expression:
                                                    "optionalFilingMethodSelection",
                                                },
                                              }),
                                              _c("small", [
                                                _vm._v(
                                                  "* If your city/county is not listed in the dropdown above, please email us so\n                  we can determine if it can be added and facilitate the filing process for you."
                                                ),
                                              ]),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ]
                                  ),
                                ],
                                1
                              )
                            }
                          ),
                          0
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                true
              ),
            })
          }),
          _c(
            "div",
            { staticClass: "optional-products-carousel-controls-container" },
            [
              _c(
                "div",
                { staticClass: "optional-products-carousel-controls" },
                [
                  _c(
                    "button",
                    {
                      attrs: { "aria-label": "previous button" },
                      on: {
                        click: function ($event) {
                          return _vm.prev()
                        },
                      },
                    },
                    [
                      _c("feather-icon", {
                        attrs: {
                          type: "chevron-left",
                          "stroke-width": "1",
                          width: "50",
                          height: "50",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "button",
                    {
                      attrs: { "aria-label": "continue button" },
                      on: {
                        click: function ($event) {
                          return _vm.next()
                        },
                      },
                    },
                    [
                      _c("feather-icon", {
                        attrs: {
                          type: "chevron-right",
                          "stroke-width": "1",
                          width: "50",
                          height: "50",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
            ]
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }